// 00.000.000/0000-00
export const maskCNPJ = (value: string) => value
  .replace(/\D+/g, '')
  .replace(/(\d{2})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1/$2')
  .replace(/(\d{4})(\d)/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

// 000.000.000-00
export const maskCPF = (value: string) => value
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{2})\d+?$/, '$1');

// 00000-000
export const maskCEP = (value: string) => value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');

// (00) 00000-0000
export const maskPhone = (value: string) => value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '($1) $2')
  .replace(/(\d{5})(\d{4})(\d)/, '$1-$2');

// 000.000.000-00 OR 00.000.000/0000-00
export const maskCPForCNPJ = (value: string) => (value.length > 14
  ? value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
  : value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2'));

// ONLY LETTERS
export const maskOnlyLetters = (value: string) => value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '');

// ONLY NUMBER
export const maskOnlyNumbers = (value: string) => value.replace(/\D/g, '');

// STRING OR 000.000.000-00
export const maskStringOrCPF = (value: string) => {
  const cleanedValue = value.replace(/\D/g, '');

  if (cleanedValue.length === 11) {
    return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else {
    return value;
  }
};
