import { cloneElement } from 'react';
import { AuthProvider } from '../useAuth';
import { ClinicProvider } from '../useClinic';
import { DoctorProvider } from '../useDoctor';
import { GuideProvider } from 'hooks/useGuide';
import { SingularProvider } from '../useSingular';
import { ThemeProvider } from '../useTheme';
import { UserProvider } from '../useUsers';

import { ContextProviderProps, ProviderComposerProps } from './types';

const providers = [
  ThemeProvider,
  AuthProvider,
  ClinicProvider,
  SingularProvider,
  DoctorProvider,
  UserProvider,
  GuideProvider,
];

const ProviderComposer = ({ contexts, children }: ProviderComposerProps) => contexts.reduce(
  (kids, Parent) => cloneElement(<Parent />, {
    children: kids,
  }),
  children,
);

function ContextProvider({ children }: ContextProviderProps) {
  return (
    <ProviderComposer
      contexts={providers}
    >
      {children}
    </ProviderComposer>
  );
}

export default ContextProvider;
